import React from "react";

import { Helmet } from "react-helmet";

/**
 * @type SeoProps
 */
export type SeoProps = {
    canonical?: string;
    metaDesc?: string;
    metaKeywords?: string;
    metaRobotsNofollow?: string;
    metaRobotsNoindex?: string;
    opengraphAuthor?: string;
    opengraphDescription?: string;
    opengraphModifiedTime?: string;
    opengraphPublishedTime?: string;
    opengraphPublisher?: string;
    opengraphSiteName?: string;
    opengraphTitle?: string;
    opengraphType?: string;
    opengraphUrl?: string;
    schema?: {
        raw: string;
    };
    title?: string;
};

/**
 * @const Seo
 */
const Seo = (props: SeoProps) => {
    const {
        canonical,
        metaDesc,
        metaKeywords,
        metaRobotsNofollow,
        metaRobotsNoindex,
        opengraphAuthor,
        opengraphDescription,
        opengraphModifiedTime,
        opengraphPublishedTime,
        opengraphPublisher,
        opengraphSiteName,
        opengraphTitle,
        opengraphType,
        opengraphUrl,
        schema,
        title
    } = props;

    const url = process.env.SITE_URL as string;

    return (
        <Helmet>
            { canonical && (
                <link href={ `${ url }${ canonical }` } rel="canonical" />
            ) }
            { metaDesc && (
                <meta content={ metaDesc } name="description" />
            ) }
            { metaKeywords && (
                <meta content={ metaKeywords } name="keywords" />
            ) }
            { metaRobotsNofollow && metaRobotsNoindex && (
                <meta content={ `${ metaRobotsNoindex } ${ metaRobotsNofollow }` } name="robots" />
            ) }
            { opengraphAuthor && (
                <meta content={ opengraphAuthor } property="article:author" />
            ) }
            { opengraphDescription && (
                <meta content={ opengraphDescription } property="og:description" />
            ) }
            { opengraphModifiedTime && (
                <meta content={ opengraphModifiedTime } property="article:modified_time" />
            ) }
            { opengraphPublishedTime && (
                <meta content={ opengraphPublishedTime } property="article:published_time" />
            ) }
            { opengraphPublisher && (
                <meta content={ opengraphPublisher } property="article:publisher" />
            ) }
            { opengraphSiteName && (
                <meta content={ opengraphSiteName } property="og:site_name" />
            ) }
            { opengraphTitle && (
                <meta content={ opengraphTitle } property="og:title" />
            ) }
            { opengraphType && (
                <meta content={ opengraphType } property="og:type" />
            ) }
            { opengraphUrl && (
                <meta content={ opengraphUrl } property="og:url" />
            ) }
            { schema && (
                <script type="application/ld+json">
                    { schema.raw }
                </script>
            ) }
            { title && (
                <title>{ title }</title>
            ) }
        </Helmet>
    );
};

export default Seo;
